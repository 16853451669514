// Packages
import React, { useEffect, useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import {
  ArrowLeftIcon,
  Form,
  FormTextInput,
  FormikProps,
} from "@sosafe-platform-engineering/fe-lib-ui-react";
import {
  Card,
  Button,
  Select,
} from "@sosafe-platform-engineering/fe-lib-ui-mantine-react";

// Hooks
import useUser from "shared/hooks/use-user";
import { useAccount } from "account/hooks/use-account";
import { useSoSafeAnalytics } from "shared/hooks/use-sosafe-analytics";

// Utilities
import {
  getNameAndDerivativeNameFromCode,
  parseCodeLanguageToISO6391,
  parseCodeLanguageToLanguageType,
  sortLanguages,
} from "shared/utilities/languages";
import Validators from "shared/utilities/validators";
import { FormikState, Field, FieldProps } from "formik";

// Components
import Helmet from "shared/components/helmet";

// Types
import { UserType } from "types";
import { ANALYTICS_TYPE } from "shared/modules/sosafe-connect";
import PersonalInformationSVG from "../svgs/personal_information.svg";

// Scss
import style from "./account.module.css";

type FormDataType = Pick<
  UserType,
  "firstname" | "lastname" | "email" | "language"
> & {
  gender: string;
};

type ResetFormType = {
  (
    nextState?:
      | Partial<
          FormikState<{
            oldpassword: string;
            newpassword: string;
            newpasswordRepeat: string;
          }>
        >
      | undefined
  ): void;
  (): void;
};

type PasswordFormType = {
  oldpassword: string;
  newpassword: string;
  newpasswordRepeat?: string;
};

export default function AccountPage() {
  const { t, i18n } = useTranslation([
    "translations",
    "flamingo",
    "helmetTranslations",
    "languagesTranslations",
  ]);

  const { user, loading } = useUser({});
  const showPasswordSection = user && !!user?.email && !user.use_sso;
  const requireFields = !!user?.email;

  const GENDER = [
    { value: "", label: t("flamingo:not_specified") },
    { value: "0", label: t("flamingo:male") },
    { value: "1", label: t("flamingo:female") },
    { value: "2", label: t("flamingo:diverse") },
  ];

  const { sendSoSafeAnalytics } = useSoSafeAnalytics();

  useEffect(() => {
    sendSoSafeAnalytics({ type: ANALYTICS_TYPE.PAGE_VIEWED });
  }, []);

  useEffect(() => {
    if (!loading && i18n.language !== user?.language) {
      i18n.changeLanguage(user?.language);
      window.document.documentElement.lang = user?.language || "en";
    }
  }, [i18n, user, loading]);

  const customerLanguage = useMemo(() => {
    if (loading) return [];
    const languagesCode = user?.customer?.languages.languages ?? [];
    const languages = parseCodeLanguageToLanguageType(languagesCode);
    return sortLanguages(languages);
  }, [loading, user?.customer?.languages.languages]);

  const fallbackOptions = [
    { value: "en", label: t("languagesTranslations:en") },
  ];

  const availableLanguages = !customerLanguage.length
    ? fallbackOptions
    : customerLanguage;

  const formRef = useRef<FormikProps<FormDataType>>(null);

  const initialValues: FormDataType = {
    gender: user?.gender?.toString() || "",
    firstname: user?.firstname || "",
    lastname: user?.lastname || "",
    email: user?.email || "",
    language: parseCodeLanguageToISO6391(user?.language || "en"),
  };

  const passwordValues = {
    oldpassword: "",
    newpassword: "",
    newpasswordRepeat: "",
  };

  const onSubmit = (data) => {
    save({ ...data, gender: data.gender === "" ? null : data.gender });
  };

  const validateForm = (values) => {
    const errors = {};
    if (
      values.email !== "" &&
      !/^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i.test(
        values.email
      )
    ) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      errors.email = t("flamingo:email_error_invalid");
    }
    return errors;
  };

  const validatePasswordForm = (values) => {
    const errors = {};
    const passwordErrors = Validators.password(values.newpassword).data as [];
    if (values.newpassword && !Validators.password(values.newpassword).valid) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      errors.passwordRequirements = (
        <div>
          <div>{t("flamingo:password_rules_intro")}</div>
          <ul className="ProfilePage-errorList">
            {passwordErrors.map((errMsg, i) => (
              <li key={i}>{t(`flamingo:password_rules_${errMsg}` as any)}</li>
            ))}
          </ul>
        </div>
      );
    }
    if (values.newpassword !== values.newpasswordRepeat) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      errors.newpasswordRepeat = t("password_rules_equal");
    }
    return errors;
  };

  const { save, changePassword } = useAccount();

  const savePassword = async (
    values: PasswordFormType,
    resetForm: ResetFormType
  ) => {
    const data = {
      newpassword: values.newpassword,
      oldpassword: values.oldpassword,
    };
    await changePassword(data);
    resetForm();
  };

  const onBackClick = () => window.history.go(-1);

  return (
    <React.Fragment>
      <Helmet title={t("helmetTranslations:Account management")} />
      <div className={style.container}>
        <button className={style.back} onClick={onBackClick}>
          <ArrowLeftIcon />
          <span>{t("flamingo:back")}</span>
        </button>
        <h5 className={style.title}>{t("flamingo:personal_info")}</h5>
        <Card withBorder radius={"lg"} className={style.card}>
          <Form<FormDataType>
            initialValues={initialValues}
            enableReinitialize
            validateOnChange
            validate={validateForm}
            onSubmit={onSubmit}
            innerRef={formRef}
          >
            <div className={style.formContainer}>
              <div className={style.formSubContainer}>
                <Field name="gender">
                  {({ field, form }: FieldProps<FormDataType>) => (
                    <Select
                      className={style.formDropdown}
                      label={t("flamingo:salutation")}
                      placeholder={t("flamingo:salutation")}
                      data={GENDER}
                      value={typeof field.value === "string" ? field.value : ""}
                      onChange={(value) => {
                        form.setFieldValue("gender", value);
                        form.setFieldTouched("gender", true);
                      }}
                    />
                  )}
                </Field>
              </div>
              <div className={style.formSubContainer}>
                <FormTextInput
                  label={t("flamingo:firstname")}
                  name="firstname"
                  placeholder={t("flamingo:firstname")}
                  required={requireFields}
                  autoComplete="on"
                />
                <FormTextInput
                  label={t("flamingo:lastname")}
                  name="lastname"
                  placeholder={t("flamingo:lastname")}
                  required={requireFields}
                  autoComplete="on"
                />
              </div>
              <div className={style.formSubContainer}>
                <FormTextInput
                  label={t("flamingo:email_label")}
                  name="email"
                  placeholder={t("flamingo:email_label")}
                  disabled
                  type="email"
                  autoComplete="on"
                />
                <Field name="language">
                  {({ field, form }: FieldProps<FormDataType>) => (
                    <Select
                      className={style.formDropdown}
                      label={t("flamingo:language_label")}
                      placeholder={t("flamingo:language_label")}
                      data={availableLanguages.map((avLang) => ({
                        value: avLang.key,
                        label: avLang.value,
                      }))}
                      autoComplete="off"
                      value={typeof field.value === "string" ? field.value : ""}
                      onChange={(value) => {
                        const { languageName, derivativeLanguage } =
                          getNameAndDerivativeNameFromCode(value || "en");
                        const newLanguageValue = derivativeLanguage
                          ? `${languageName}-${derivativeLanguage}`
                          : languageName;
                        form.setFieldValue("language", newLanguageValue);
                        form.setFieldTouched("language", true);
                      }}
                    />
                  )}
                </Field>
              </div>
            </div>
            {user?.game?.certificate && (
              <span className="certificate-warning-text">
                {t("translations:user_profile_certificate_warning")}
              </span>
            )}
            <div className={style.saveContainer}>
              <Button
                type="submit"
                aria-label={t("flamingo:save")}
                className={style.save}
              >
                {t("flamingo:save")}
              </Button>
            </div>
          </Form>
        </Card>
        {showPasswordSection && (
          <Card withBorder radius={"lg"} className={style.passwordCard}>
            <Form<PasswordFormType>
              initialValues={passwordValues}
              onSubmit={(values, { resetForm }) =>
                savePassword(values, resetForm)
              }
              validate={validatePasswordForm}
            >
              <FormTextInput
                label={t("flamingo:old_password")}
                className={style.formPasswordInput}
                name="oldpassword"
                placeholder={t("flamingo:old_password")}
                type="password"
                autoComplete="off"
              />
              <FormTextInput
                label={t("flamingo:password")}
                className={style.formPasswordInput}
                name="newpassword"
                placeholder={t("flamingo:password")}
                type="password"
                autoComplete="off"
              />
              <FormTextInput
                label={t("flamingo:repeat_password")}
                className={style.formPasswordInput}
                name="newpasswordRepeat"
                placeholder={t("flamingo:repeat_password")}
                type="password"
                autoComplete="off"
              />
              <div className={style.saveContainer}>
                <Button
                  aria-label={t("flamingo:change_password")}
                  type="submit"
                  className={style.save}
                >
                  {t("flamingo:change_password")}
                </Button>
              </div>
            </Form>
          </Card>
        )}
        <PersonalInformationSVG className={style.svg} />
      </div>
    </React.Fragment>
  );
}
