// Packages
import { useTranslation } from "react-i18next";
import { Card, Title } from "@sosafe-platform-engineering/fe-lib-ui-mantine-react";
import React, { useMemo } from "react";

import ExperienceSpiderChart from "gamification/components/experience-spider-chart";
import ExperienceCakeChart from "gamification/components/experience-cake-chart";
import ExperienceLegendBubble from "gamification/components/experience-legend-bubble";
import { useCategoriesRequest } from "elearning/hooks/use-categories-request";
import { calculateCategoryProgressByGroupId, groupCategoryByGroupId } from "flamingo-e-learning-platform/utils";

import useUser from "shared/hooks/use-user";
import style from "./experience.module.css";

const COLORS = [
  "#F7B500",
  "#00C39A",
  "#005547",
  "#018090",
  "#949FFF",
  "#5F5E86",
  "#E16E3A",
];

export const ExperienceCard = () => {
  const { t } = useTranslation("translations");
  const { data: categories, isLoading } = useCategoriesRequest();
  const { user } = useUser({ enabled: true });

  const dataChart = useMemo(() => {
    if (!categories || !user?.language) return [];

    const categoriesByGroup = groupCategoryByGroupId(categories.data.result)
    const combinedCategoriesPerGroup = calculateCategoryProgressByGroupId(Object.values(categoriesByGroup), user.language);

    return combinedCategoriesPerGroup.map((category, i) => ({
      ...category,
      color: COLORS[i % COLORS.length],
    }));
  }, [categories, user?.language]);

  return (
    <Card withBorder radius={"lg"} className={style.experience}>
      <div className={style.title}>
        <Title order={3} size="h4">{t("Experience")}</Title>
      </div>

      <div className={style.loader}>
        {!isLoading && !dataChart?.length && <p>{t("Loading failed")}</p>}

        {dataChart.length > 3 && (
          <ExperienceSpiderChart
            className={style.element}
            data={dataChart}
            loading={isLoading}
          />
        )}

        {dataChart.length < 4 && (
          <ExperienceCakeChart
            className={style.element}
            data={dataChart}
            loading={isLoading}
          />
        )}
      </div>

      <div>
        <div>
          {dataChart.map((c, i) => (
            <div className={style.legend} key={i.toString()}>
              <div className={style.bubble}>
                <ExperienceLegendBubble
                  size="21"
                  color={c.color}
                  className={style.marginLeftRight}
                  number={i}
                />
              </div>
              <p className={style.legendItem}>{c.name}</p>
            </div>
          ))}
        </div>
      </div>
    </Card>
  );
};

export default ExperienceCard;
