import ISO6391 from 'iso-639-1';

type LanguageProps = { key: string, value: string };

export const LANGUAGE_MANAGER: Array<LanguageProps> = [
  { key: 'de', value: 'Deutsch' },
  { key: 'en', value: 'English' },
  { key: 'ar', value: 'عربي' },
  { key: 'bg', value: 'български' },
  { key: 'ch', value: 'Deutsch (CH)' },
  { key: 'cs', value: 'čeština' },
  { key: 'da', value: 'Dansk' },
  { key: 'el', value: 'Ελληνικά' },
  { key: 'es', value: 'Español' },
  { key: 'et', value: 'eesti keel' },
  { key: 'fi', value: 'Suomi' },
  { key: 'fr', value: 'Français' },
  { key: 'hu', value: 'Magyar' },
  { key: 'hr', value: 'Hrvatski' },
  { key: 'it', value: 'Italiano' },
  // { key: 'ja', value: '日本語' },
  { key: 'jp', value: '日本語' }, // ToDo: remove, once backend uses the proper ISO-639-1 code ("JA")
  { key: 'nl', value: 'Nederlands' },
  { key: 'no', value: 'Norsk' },
  { key: 'pl', value: 'Polski' },
  { key: 'pt', value: 'Português' },
  { key: 'ro', value: 'Română' },
  { key: 'ru', value: 'Pусский' },
  { key: 'se', value: 'Svenska' }, // ToDo: remove, once backend uses the proper ISO-639-1 code ("SV")
  { key: 'sk', value: 'Slovenský' },
  { key: 'sl', value: 'Slovenščina' },
  // { key: 'sv', value: 'Svenska' },
  { key: 'tr', value: 'Türkçe' },
  { key: 'vi', value: 'tiếng Việt' },
  { key: 'zh', value: '中文' },
  { key: 'ko', value: '한국어' },
  { key: 'th', value: 'ไทย' },
  { key: 'hi', value: 'हिन्दी' },
  { key: 'uk', value: 'український' },
];


/**
* sosafe has not implemented code language following ISO6392 and therefore,
* we are correcting few code languages here, until we have fixed in the database and backend.
* (until there we use this parser)
* */
const SOSAFE_LANG_CODE_TO_ISO6392 = {
  jp: 'ja',
  ch: 'de-ch',
  se: 'sv',
  nb: 'no',
};

// Sosafe does NOT use the ISO6392 this is a map to be used from iso6392 to sosafe language code
export const SOSAFE_LANGUAGE_CODE_PARSER = new Map();
SOSAFE_LANGUAGE_CODE_PARSER.set('ja', 'jp');
SOSAFE_LANGUAGE_CODE_PARSER.set('sv', 'se');
SOSAFE_LANGUAGE_CODE_PARSER.set('nb', 'no');
SOSAFE_LANGUAGE_CODE_PARSER.set('de-ch', 'ch');

export const parseCodeLanguageToISO6391 = (code: string) => {
  const fixedCode = SOSAFE_LANG_CODE_TO_ISO6392[code];
  return fixedCode ?? code;
};

export const LANGUAGE_MANAGER_ISO = LANGUAGE_MANAGER.map(({ key, value }) => ({
  key: parseCodeLanguageToISO6391(key),
  value
}))

export const parseCodeLanguageArrayToISO6391 = (codeArray: string[]) => codeArray.map((code) => ({
  sosafeCodeLanguage: code,
  ISO6391: parseCodeLanguageToISO6391(code),
}));

export const getNameAndDerivativeNameFromCode = (code: string) => {
  const parts = code.split('-');
  return {
    languageName: parts?.[0] ?? code,
    derivativeLanguage: parts?.[1],
  }
}

export const getCodeByLanguageName = (languageName: string) => {
  const code = ISO6391.getCode(languageName);
  return code;
}

export const getLanguageISO6391Name = (code: string) => {
  const isDerivativeLanguage = code.includes('-');

  if (isDerivativeLanguage) {
    const { languageName, derivativeLanguage } = getNameAndDerivativeNameFromCode(code);
    return `${ISO6391.getNativeName(languageName)} (${derivativeLanguage.toUpperCase()})`;
  }

  return ISO6391.getNativeName(code);
};

export const parseCodeLanguageToLanguageType = (codeArray: string[]) => codeArray.map((code) => {
  const parsedCodeLanguage = parseCodeLanguageToISO6391(code);
  return {
    key: parsedCodeLanguage,
    value: getLanguageISO6391Name(parsedCodeLanguage)
  };
})

// Languages with high priority
const SOSAFE_PRIO_LANGUAGES = ['en', 'de', 'fr'];


type SeparateByPrioProps = {
  prioLanguage: Array<LanguageProps>,
  restLanguage: Array<LanguageProps>
}

const separateLanguageByPriority = (codeArray: Array<LanguageProps>) =>
  codeArray.reduce((acc: SeparateByPrioProps, curLanguage) => {
    const isPrio = SOSAFE_PRIO_LANGUAGES.includes(curLanguage.key);
    if (isPrio) return { ...acc, prioLanguage: [...acc.prioLanguage, curLanguage] };
    return { ...acc, restLanguage: [...acc.restLanguage, curLanguage] };
  }, { prioLanguage: [], restLanguage: [] });


const sortLanguagesAlphabetically = (languages: Array<LanguageProps>) => languages.sort((a, b) => {
  if (a.value < b.value) return -1
  if (a.value > b.value) return 1;
  return 0;
});

const sortPrioLanguages = (languages: Array<LanguageProps>) =>
  languages.sort(a => {
    if (a.key === 'en') return -1;
    if (a.key === 'de') return 0;
    return 1
  })

/*
  Sort language code first by language prio and then alphabetically
  eg: Prio languages ['en', 'de', 'fr']
*/
export const sortLanguages = (languages: Array<LanguageProps>) => {
  const { prioLanguage, restLanguage } = separateLanguageByPriority(languages);
  const sortedPrioLanguages = sortPrioLanguages(prioLanguage);
  const sortedRestLanguages = sortLanguagesAlphabetically(restLanguage);
  return [...sortedPrioLanguages, ...sortedRestLanguages];
};

export const isLangSupported = (lang: string) => LANGUAGE_MANAGER.find((langData) => langData.key === lang)

export const DEFAULT_LANGUAGE = 'en'
