import { Model, Question } from "survey-core";
import { SurveySummaryResult } from "../types";

/**
 * @description - This Object contains the mapping of the question type to the custom component that will be used to render the question.
 * */
const customSoSafeItemComponentMap = {
  checkbox: "sosafe-checkbox-item",
};

/**
 * @description - This Object contains the default settings for the survey that fits SoSafe's requirements.
 * */
const SURVEY_DEFAULTS = {
  showPreviewBeforeComplete: "showAnsweredQuestions",
  showCompletedPage: false,
  questionsOnPageMode: "questionPerPage",
  showQuestionNumbers: "off",
  showProgressBar: "off",
  showNavigationButtons: false,
};

/**
 * @param {surveyJson} - The survey configuration object
 * @description - This function adds the property 'itemComponent' to the survey config depending on
 * the type of the question. This is used to render custom components for the survey questions.
 * */
export const addCustomItems = (surveyJson: any) => {
  const pages = surveyJson.pages.map((page) => {
    const customElements = page.elements.map((element) => ({
      ...element,
      itemComponent: customSoSafeItemComponentMap[element.type],
    }));

    return {
      ...page,
      elements: customElements,
    };
  });

  return {
    ...surveyJson,
    pages,
  };
};

/**
 * @param {surveyJson} - The survey configuration object
 * @description - This function removes the title property from the survey configuration object,
 * this makes sure that the title is not displayed in the survey.
 * */
export const sanitizeSurveyModel = (surveyJson: any) => {
  const pages = surveyJson.pages.map((page) => ({
    ...page,
    title: undefined,
  }));

  return {
    ...surveyJson,
    title: undefined,
    pages,
  };
};

/**
 * @param {surveyJson} - The survey configuration object
 * @description - This function adds the default settings for the survey that fits SoSafe's requirements.
 * */
export const attachSoSafeSurveyDefaults = (surveyJson: any) => ({
  ...surveyJson,
  ...SURVEY_DEFAULTS,
});

/**
 * @description - This function returns the custom css classes for the survey questions.
 * This is used to style the survey questions to fit SoSafe's requirements.
 * add here extra classes for the survey styles
 * */
export const customCssSurvey = (prefix: string) => ({
  root: `${prefix}-sosafe-surveys-root`,
  body: `${prefix}-sosafe-surveys-body`,
  header: `${prefix}-sosafe-surveys-header`,
  content: `${prefix}-sosafe-surveys--stylescontent`,
  container: `${prefix}-sosafe-surveys-container`,
  page: {
    root: `${prefix}-sosafe-surveys-page`,
  },
  panel: {
    title: `${prefix}-sosafe-surveys-panel-title`,
  },
  question: {
    title: `${prefix}-sosafe-surveys-panel-title`,
    mainRoot: `${prefix}-sosafe-surveys-question`,
    withFrame: `${prefix}-sosafe-surveys-question-withframe`,
    content: `${prefix}-sosafe-surveys-question-content`,
    header: `${prefix}-sosafe-surveys-question-header`,
    description: `${prefix}-sosafe-surveys-question-description`,
  },
  radiogroup: {
    root: `${prefix}-sosafe-surveys-question-radiogroup`,
    item: `${prefix}-sosafe-surveys-question-radiogroup-item`,
    materialDecorator: `${prefix}-sosafe-surveys-radiogroup-materialdecorator`,
    itemChecked: `${prefix}-sosafe-surveys-radiogroup-itemchecked`,
    itemControl: `${prefix}-sosafe-surveys-radiogroup-itemcontrol`,
    controlLabel: `${prefix}-sosafe-surveys-radiogroup-controllabel`,
  },

  checkbox: {
    root: `${prefix}-sosafe-surveys-question-radiogroup`,
  },
  ranking: {
    itemContent: "sosafe-ranking-item__content",
    itemIconContainer: "sosafe-ranking-item__icon-container",
    itemIcon: "sosafe-ranking-item__icon",
    itemIconHoverMod: "sosafe-ranking-item__icon--hover",
    itemIconFocusMod: "sosafe-ranking-item__icon--focus",
    itemGhostMod: "sosafe-ranking-item--ghost",
    itemDragMod: "sosafe-ranking-item--drag",
  },
});

export const isSurveyPassed = (result: Array<SurveySummaryResult>) => result.every((r) => r.passed);
