function getLuminance(rgb) {
  const [r, g, b] = rgb.map((v) => {
    const channel = v / 255;
    return channel <= 0.03928 ? channel / 12.92 : ((channel + 0.055) / 1.055)**2.4;
  });

  return 0.2126 * r + 0.7152 * g + 0.0722 * b;
}

export const getContrastColor = (backgroundColor) => {
  const rgb = backgroundColor
  .slice(1)
  .match(/.{2}/g)
  .map((hex) => parseInt(hex, 16));

  const luminance = getLuminance(rgb);

  return luminance > 0.3 ? '#000000' : '#FFFFFF';
}
