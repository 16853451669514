import React, { useEffect, useMemo, useState } from "react";

import {
  ArrowRightIcon,
  CheckCircleIcon,
  VeraAnnotationXIcon,
  Tag,
} from "@sosafe-platform-engineering/fe-lib-ui-react";

import {
  Card,
  Button,
  Progress,
  Title,
  Text,
} from "@sosafe-platform-engineering/fe-lib-ui-mantine-react";
import Loading from "shared/components/loading";
import { Model } from "survey-core";
import { Survey } from "survey-react-ui";
import { PersonalizedModule } from "flamingo-e-learning-platform/training/types/module.type";
import { TagProps } from "@sosafe-platform-engineering/fe-lib-ui-react/dist/components/tag";
import { useTranslation } from "react-i18next";
import { usePostSurveyAnswers } from "survey-hub/hooks/use-post-survey-answer";
import {
  addCustomItems,
  attachSoSafeSurveyDefaults,
  customCssSurvey,
  sanitizeSurveyModel,
  isSurveyPassed,
} from "../../utils/survey-utils";
import { registerSoSafeCheckboxItem } from "../survey-custom-elements/survey-checkbox";
import "../awareness-assessment-sosafe-survey-style.css";
import "../awareness-assessment-ranking.css";
import useUser from "shared/hooks/use-user";
import { UseShowDecisionAlert } from "flamingo-e-learning-platform/utilities/modal-alert/use-show-decision-alert";
import { ALERT_TYPE } from "flamingo-e-learning-platform/utilities/modal-alert/shared";
import ErrorCollapsible from "shared/components/error-collapsible";
import useMetaLinks from "shared/hooks/use-meta-links";
import { useHistory, useLocation } from "react-router";
import { queryClient, queryKey } from "shared/modules/sosafe-connect";
import {
  AwarenessAssessmentRouteStateType,
  SurveySummaryResult,
} from "flamingo-e-learning-platform/awareness-assessment/types";
import { parseCodeLanguageToISO6391 } from "shared/utilities/languages";
import { AwarenessAssessmentFeedback } from "../awareness-assessment-feedback";
import style from "./awareness-assessment-survey.module.css";

interface AwarenessAssessmentSurveyProps {
  surveyData: any;
  surveyId: number;
  module: PersonalizedModule;
}

export const AwarenessAssessmentSurvey = ({
  surveyData,
  surveyId,
  module,
}: AwarenessAssessmentSurveyProps) => {
  const [survey, setSurvey] = useState<Model>();
  const history = useHistory();
  const location = useLocation<AwarenessAssessmentRouteStateType>();
  const { user } = useUser({});
  const [isNextDisabled, setIsNextDisabled] = useState(true);
  const { t } = useTranslation("awareness-assessment");
  const { t: translate } = useTranslation([
    "messageTranslations",
    "translations",
  ]);
  const [surveyResult, setSurveyResult] =
    useState<Array<SurveySummaryResult> | null>(location.state?.answer ?? null);

  const sosafeSurvey = useMemo(() => {
    const sanitizedSurvey = sanitizeSurveyModel(surveyData);
    const surveyWithCustomItems = addCustomItems(sanitizedSurvey);
    const surveyWithSoSafeDefaults = attachSoSafeSurveyDefaults(
      surveyWithCustomItems
    );
    return surveyWithSoSafeDefaults;
  }, []);

  const link = useMetaLinks();

  const { show, close } = UseShowDecisionAlert();

  const errorAlert = (error: unknown) => {
    const onClose = async () => {
      history.push("/");
      close();
    };

    show({
      onClose,
      title: (
        <Title>{translate("messageTranslations:An error has occured")}</Title>
      ),
      content: (
        <>
          <ErrorCollapsible error={error} />
        </>
      ),
      footer: (
        <>
          <Button
            variant="primary"
            onClick={() => window.location.replace(link.support)}
          >
            {translate("translations:Go to support")}
          </Button>
          <Button variant="secondary">
            {translate("translations:Back to the home page")}
          </Button>
        </>
      ),
      type: ALERT_TYPE.ERROR,
    });
  };

  const postSurveyResult = usePostSurveyAnswers({
    onSucess: (result) => {
      try {
        if (!survey) throw "Survey was not initialized correctly";
        if (!result.validationResult)
          throw "Not able to validate the survey result";

        const surveySummary = result.validationResult.map((question) => {
          const { passed } = question;
          const text = survey.getQuestionByName(question.questionName).title;
          return { text, passed };
        });

        setSurveyResult(surveySummary);

        // add answered flag to location state to prevent the user from
        // restarting the survey
        location.state = { ...(location.state ?? {}), answer: surveySummary };
        history.replace(location);

        // invalidate queries to fetch new data once the user goes to training
        // or achievements page
        queryClient.invalidateQueries([queryKey.ELEARNING]);
        queryClient.invalidateQueries([queryKey.BADGES]);
        queryClient.invalidateQueries([queryKey.ACTIVITIES]);
      } catch (e) {
        errorAlert(e);
      }
    },
    onError: (e) => {
      errorAlert(e);
    },
  });

  useEffect(() => {
    if (!user?.language) return;
    registerSoSafeCheckboxItem();

    const surveyModel = new Model(sosafeSurvey);
    surveyModel.css = customCssSurvey("awareness-assessment");

    const language = parseCodeLanguageToISO6391(user?.language || "en");
    surveyModel.locale = language;

    setSurvey(surveyModel);
  }, [sosafeSurvey, user?.language]);

  const onNext = () => {
    if (survey && survey.currentPageNo === survey.pageCount - 1) {
      survey?.doComplete();
      return;
    }
    survey?.nextPage();
  };

  const onComplete = () => {
    if (!survey) return;
    postSurveyResult.mutate({
      userId: user?.id || 0,
      surveyId,
      answers: survey?.data,
    });
  };

  const onCurrentPageChanged = () => {
    setIsNextDisabled(true);
  };

  const onValueChanged = () => {
    if (survey) {
      const answer = survey.getValue(survey.activePage.questions[0].name);
      setIsNextDisabled(!answer?.length);
    }
  };

  const tagProps = useMemo((): Pick<TagProps, "variant" | "icon"> => {
    if (surveyResult === null)
      return { icon: ArrowRightIcon, variant: undefined };
    const passed = isSurveyPassed(surveyResult);
    if (passed) return { icon: CheckCircleIcon, variant: "success" };
    return { icon: VeraAnnotationXIcon, variant: "error" };
  }, [surveyResult]);

  if (!survey) return <Loading />;

  return (
    <div>
      <Card withBorder radius={"lg"} className={`${style.container}`}>
        <div className={`${style.header}`}>
          <Tag
            className={style.tag}
            variant={tagProps.variant}
            content={`${t("lesson")}: ${module.name}`}
            icon={tagProps.icon}
          />
          {!surveyResult && (
            <div className={`${style.progressbarContainer}`}>
              <Progress
                role="progressbar"
                aria-valuenow={survey.currentPageNo}
                aria-valuemin={1}
                aria-valuemax={survey.pageCount}
                aria-labelledby="survey-progress"
                className={`${style.progressbar}`}
                value={Math.floor(
                  (survey.currentPageNo / survey.pageCount) * 100
                )}
              />
              <label id="survey-progress">
                <span className="sr-only">{t("step")}</span>
                <span>{`${survey.currentPageNo + 1} ${t(
                  "outOf"
                )} ${survey?.pageCount}`}</span>
                <span className="sr-only">{t("steps")}</span>
              </label>
            </div>
          )}
        </div>
        {!surveyResult && (
          <Survey
            model={survey}
            onCurrentPageChanged={onCurrentPageChanged}
            onValueChanged={onValueChanged}
            onComplete={onComplete}
          />
        )}
        {surveyResult && (
          <AwarenessAssessmentFeedback module={module} result={surveyResult} />
        )}
        {!surveyResult && (
          <div className={style.footer}>
            <Button
              data-testid="next-button"
              disabled={isNextDisabled}
              className={style.nextButton}
              aria-label={t("next")}
              onClick={onNext}
            >
              {t("next")}
            </Button>
          </div>
        )}
      </Card>
    </div>
  );
};
