import { Badge } from "flamingo-e-learning-platform/components/badge/badge";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import useUser from "shared/hooks/use-user";
import { BadgeProps } from "types";

const HEIGHT_PROP = "height=128";
const GRAYSCALE_PROP = "&operations=grayscale";

interface UseLastBadgesProps {
  onClick: (badge: BadgeProps) => void;
  maxNumberOfBadges?: number;
}

/**
 * Get "Last badges" from Game Activities and create an array of <Badge/>
 *
 * @param {function} onClick     Trigger when clicking a badge
 * @param {number}   maxLength   Limit number of badges returned in the array
 *
 * @returns Array of <Badge/> components
 */
export const useLastBadges = ({
  onClick,
  maxNumberOfBadges,
}: UseLastBadgesProps) => {
  const { user } = useUser({ enabled: false });
  const lastBadges = user?.game.activities.lastBadges;
  const { t } = useTranslation("flamingo");
  const badges =
    maxNumberOfBadges && (lastBadges?.length ?? 0) > maxNumberOfBadges
      ? lastBadges?.slice(0, maxNumberOfBadges)
      : lastBadges;

  const badgeComponents = useMemo(
    () =>
      badges?.map((badge) => (
        <Badge
          key={badge.id}
          name={badge.name}
          url={`${badge.image}?${HEIGHT_PROP}${
            !badge.received ? GRAYSCALE_PROP : ""
          }`}
          onClick={() => onClick(badge)}
          alt={`${badge?.name} - ${
            badge?.received ? t("badgeAchieved") : t("badgeNotAchieved")
          }`}
          isBadgeReceived = {badge?.received}
        />
      )) || [],
    [badges, onClick]
  );

  return badgeComponents;
};
